import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Airplane Detailer (Canton, Ohio)`}</h1>
    <p>{`This would be an example job application page.`}</p>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas et posuere est, sed gravida velit. Maecenas iaculis porttitor euismod. Morbi ut est scelerisque, vehicula ipsum a, tincidunt neque. Donec convallis ipsum et leo pulvinar, mattis condimentum turpis mollis. `}</p>
    <h2>{`Day in the life`}</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/tN72Eh4FIwc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2>{`Requirements`}</h2>
    <p>{`Donec mattis elit sit amet laoreet aliquet. Aenean non ex dignissim, dignissim nisi nec, lacinia quam.`}</p>
    <ul>
      <li parentName="ul">{`Quisque a feugiat urna, ut maximus magna. Integer sit amet tortor at eros malesuada tempor et eget odio.`}</li>
      <li parentName="ul">{`Aliquam tincidunt sagittis nisi, sed maximus ante cursus id. `}</li>
      <li parentName="ul">{`In augue est, laoreet at sem ac, egestas vulputate nibh. `}</li>
    </ul>
    <h2>{`Apply now`}</h2>
    <p>{`Donec ut lacus sed metus blandit dignissim at nec felis. Proin fermentum ipsum vitae justo venenatis, vel iaculis augue rutrum.`}</p>
    <p>{`[`}{`Embedded jot form link]`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      